<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <section class="px-4 md:px-6" :class="`${bgClass}`">
    <div class="mx-auto max-w-6xl py-8 md:pb-14 md:pt-12">
      <div
        class="w-full items-center md:grid md:grid-cols-2 md:gap-6 lg:gap-52"
      >
        <div :class="alignmentClass">
          <NuxtImg
            v-if="block.image?.[0]?.url"
            :src="block.image?.[0]?.url"
            :alt="block.image?.[0]?.title"
            loading="lazy"
            :width="block.image?.[0]?.width"
            :height="block.image?.[0]?.height"
            class="hidden w-full md:order-1 md:block"
            :class="
              props.block.isLeftAlignedImage === 'yes'
                ? 'image-left'
                : 'image-right'
            "
          />
        </div>

        <div class="flex flex-col justify-center gap-6 md:order-2 md:gap-4">
          <h2 class="text-2xl text-dynamic-primary md:mt-[-5px] md:text-3xl">
            {{ block.heading }}
          </h2>
          <NuxtImg
            v-if="block.image?.[0]?.mobileUrl"
            :src="block.image?.[0]?.mobileUrl"
            :alt="block.image?.[0]?.title"
            loading="lazy"
            :width="block.image?.[0]?.width"
            :height="block.image?.[0]?.height"
            class="max-h-[310px] rounded-lg object-contain md:hidden"
          />
          <div
            class="break-words text-dynamic-primary antialiased md:text-lg [&_a]:text-primary-default [&_a]:underline [&_li]:relative [&_li]:py-1 [&_li]:pl-9 before:[&_li]:absolute before:[&_li]:left-0 before:[&_li]:top-[calc(5%+2px)] before:[&_li]:inline before:[&_li]:size-6 before:[&_li]:bg-[url('~/assets/icons/checkmark-dark.svg')] before:[&_li]:bg-center before:[&_li]:bg-no-repeat [&_ol:last-child]:pb-0 [&_ol]:pb-3 [&_p:last-child]:pb-0 [&_p]:pb-3 [&_p]:text-md [&_p]:leading-relaxed [&_ul:last-child]:pb-0 [&_ul]:pb-3"
            v-html="block.textArea"
          ></div>
          <div class="md:order-3">
            <EditorialButtonLink
              v-if="targetLink"
              :url="targetLink"
              class="flex md:mt-3 md:inline-flex"
            >
              {{ block.linkAnchorText }}
            </EditorialButtonLink>
          </div>
          <PageBuilder :blocks="block.children" :globals="globals" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const bgClass = props?.block?.backgroundColor
  ? props.block.backgroundColor
  : 'bg-white';

const alignmentClass =
  props.block.isLeftAlignedImage === 'yes' ? 'md:order-2' : 'md:order-3';

const targetLink =
  props.block.pageLink && props.block.pageLink.length > 0
    ? props.block.pageLink[0].url
    : (props.block.pageLinkFallback ?? '');
</script>
